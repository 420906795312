<template>
  <div class="confusion">
    <!-- <div class="main-Title bgff">
      <h2>AffSub Level</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="affSubLevelFiliter" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="AffiliateIds:" label-width="80px" class="mb5">
                <group-select
                  v-model="affSubLevelFiliter.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  collapse-tags
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="SourceIds:" label-width="100px" class="mb5">
                <group-select
                  v-model="affSubLevelFiliter.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  collapse-tags
                  reserve-keyword
                  :loading="sourceLoading"
                  clearable
                  filterable
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="OfferIds" label-width="80px" class="mb5">
                <el-input
                  v-model="affSubLevelFiliter.offerIds"
                  placeholder="offerId write here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item class="mb5">
                <el-button type="primary" @click="searchAffSubLevel(1)">Search</el-button>
                <el-button type="primary" @click="addAffSubLevelClick">Add</el-button>
                <el-button type="primary" @click="delAffSubLevel">Delete</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="affSubLevelList"
          highlight-current-row
          @selection-change="handleSelectionChange"
          @selection-all="handleSelectionChange"
          border
          stripe
          style="width:100%;"
          size="mini"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="AffiliateId" prop="affiliateId" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="SourceId" prop="sourceId" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="toSourceDetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="OfferId" prop="offerId" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="detailClick(scope.row)">{{ scope.row.offerId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Affsub Level"
            prop="affsubLevel"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="Description"
            prop="description"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="Operator"
            prop="updateBy"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="Operate Time"
            prop="updateTime"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="Operation" align="center" min-width="100">
            <template slot-scope="scope">
              <el-button type="primary" @click="submitUpdateClick(scope.row)">Update</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="affSubLevelTotal"
            @handleSizeChange="handleAffSubLevelSizeChange"
            @handleCurrentChange="handleAffSubLevelCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>

      <el-dialog
        :title="dialogType === 'new' ? 'Add' : 'Update'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
      >
        <el-form :model="affSubLevels" :rules="rules" label-width="100px" ref="affSubLevel">
          <el-row>
            <el-col :span="12">
              <el-form-item label="AffiliateId:" prop="affiliateIds" v-if="dialogType === 'new'">
                <group-select
                  v-model="affSubLevels.affiliateIds"
                  :data-source="affiliatesGroupList"
                  :loading="affiliateLoading"
                  multiple
                  collapse-tags
                  reserve-keyword
                  clearable
                  filterable
                  class="w100"
                />
              </el-form-item>
              <el-form-item label="AffiliateId:" prop="affiliateId" v-else>
                <el-select
                  v-model="affSubLevels.affiliateId"
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  class="w100"
                  :disabled="dialogType == 'edit'"
                >
                  <el-option-group
                    v-for="group in affiliatesGroupList"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.children"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="SourceId:">
                <el-select
                  v-model="affSubLevels.sourceId"
                  reserve-keyword
                  :loading="sourceLoading"
                  clearable
                  filterable
                  class="w100"
                  :disabled="dialogType == 'edit'"
                >
                  <el-option-group
                    v-for="group in sourcesList"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.children"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="OfferId:">
                <el-input
                  v-model="affSubLevels.offerId"
                  :disabled="dialogType == 'edit'"
                  class="w100"
                  placeholder="write affSubLevel offerId..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="AffsubLevel:">
                <el-input
                  class="w100"
                  v-model="affSubLevels.affsubLevel"
                  placeholder="write affSubLevel..."
                ></el-input>
                <el-tag type="warning">层级区间：0~128 </el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Description:">
                <el-input
                  type="textarea"
                  class="w100"
                  v-model="affSubLevels.description"
                  placeholder="write affSubLevel Description..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addAffSubLevel()">确 定</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { getSubLevelList, addOrUpdateSubLevel, deleteSubLevel } from 'api/affiliate/affSubLevel';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import { myMixin } from '@/mixins/mixins.js';
  import { mapState, mapActions } from 'vuex';
  let clickTimer = null;
  const defaultAffSubLevels = {
    affiliateId: '',
    sourceId: '',
    offerId: '',
    period: '',
    description: '',
    affiliateIds: [],
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        affSubLevelFiliter: {
          sourceIds: null,
          affiliateIds: null,
          offerIds: null,
        },
        affSubLevels: Object.assign({}, defaultAffSubLevels),
        rules: {
          affiliateId: [{ required: true, message: '请输入affiliateId', trigger: 'blur' }],
          affiliateIds: [{ required: true, message: '请输入affiliateId', trigger: 'blur' }],
        },
        affSubLevelList: [],
        affSubLevelTotal: null,
        affSubLevelSelectedList: [],
        dialogVisible: false,
        dialogType: 'new',
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getSourceIdsList();
      this.searchAffSubLevel();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      toSourceDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.sourceId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      searchAffSubLevel(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.affSubLevelFiliter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        if (!param.offerIds) {
          delete param.offerIds;
        }
        if (Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (Array.isArray(param.affiliateIds)) {
          param.affiliateIds = param.affiliateIds.join(',');
        }
        getSubLevelList(param).then((response) => {
          if (response.code == 200) {
            this.affSubLevelList = response.result;
            this.affSubLevelTotal = response.total;
          }
        });
      },
      handleAffSubLevelSizeChange(val) {
        this.pageSize = val;
        this.handleAffSubLevelCurrentChange(1);
      },
      handleAffSubLevelCurrentChange(val) {
        this.currentPage = val;
        this.searchAffSubLevel();
      },
      addAffSubLevelClick() {
        this.affSubLevels = Object.assign({}, defaultAffSubLevels);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      addAffSubLevel() {
        this.$refs.affSubLevel.validate((valid) => {
          const params = { ...this.affSubLevels };
          if (Array.isArray(params.affiliateIds) && params.affiliateIds.length === 0) {
            delete params.affiliateIds;
          } else if (!params.affiliateId) {
            delete params.affiliateId;
          }
          if (valid) {
            addOrUpdateSubLevel(params).then((response) => {
              if (response.code == 200) {
                if (this.dialogType === 'new') {
                  this.$message({
                    message: 'Add Success',
                    type: 'success',
                  });
                } else {
                  this.$message({
                    message: 'Update Success',
                    type: 'success',
                  });
                }
                this.searchAffSubLevel();
                this.dialogVisible = false;
              } else {
                this.$message.error('Error:' + response.status.msg);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      delAffSubLevel() {
        const length = this.affSubLevelSelectedList.length;
        if (length <= 0) {
          this.$message.warning('请先选择要删除的数据~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const ids = this.affSubLevelSelectedList.map((item) => item.id)?.join();
            this.deleteAffSublevel(ids);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
        this.delArr = [];
      },
      submitUpdateClick(row) {
        this.affSubLevels = cloneDeep(row);
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      deleteAffSublevel(ids) {
        deleteSubLevel(ids).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.searchAffSubLevel();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.affSubLevelSelectedList = val;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
